import Sidebar from './sidebar';

import bmwLogoImageSrc from '../images/bmw-logo-new-white.svg';
import { COGNITO, getLoginViaWebEAMUrl, initJwt, logoutFromIdp, setLoginProvider } from './js/portal/auth';
import { baseApiAxios, showError } from './js/portal/api';
import { RO_PRINCIPLES_RULES } from './js/portal/environment';
import { checkIfAnyInList, linkWithoutSearch, showErrorFromApiOperation } from './js/portal/main';

function FooterLink({ text, hash, externalLink, linkId, visible, permissions }) {
  return (
    <>
      {(visible || checkIfAnyInList(localStorage.permissions?.split(',') || [], permissions)) && (
        <>
          {hash && (
            <a class="navbar-brand" href={linkWithoutSearch(hash)} data-hash={hash}>
              <span id={linkId} data-permissions={permissions ? permissions.join(',') : ''}>
                {text}
              </span>
            </a>
          )}
          {externalLink && (
            <a class="navbar-brand" id={linkId} href={externalLink} target="_blank" rel="noopener noreferrer">
              <span id={linkId}>{text}</span>
              <i
                class="fas fa-external-link-alt"
                style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
              />
            </a>
          )}
        </>
      )}
    </>
  );
}

export default function App() {
  const urlParams = new URLSearchParams(window.location.search);

  if (!COGNITO.userpool.getCurrentUser() && !urlParams.has('code')) {
    // Forward the user to the login page if there's no valid user login yet
    console.debug('Login required');
    const loginUrl = getLoginViaWebEAMUrl(window.location.hash, window.location.search);
    window.location.href = loginUrl;
    return;
  } else if (urlParams.has('code')) {
    // After the initial login, the user gets a code from the IdP
    setLoginProvider('idp');

    let targetHash = sessionStorage.lastHash || '#dashboard';
    let targetSearch = '';
    const encodedState = urlParams.get('state');

    if (encodedState) {
      try {
        const state = JSON.parse(atob(encodedState));

        if (state.target) {
          const { target } = JSON.parse(atob(state.target));
          if (target && target.startsWith('#')) {
            targetHash = target;
          } else {
            console.warn(`Invalid target: "${target}"! Target must start with a hash`);
          }
        }

        if (state.search) {
          const { search } = JSON.parse(atob(state.search));
          if (search && search.startsWith('?')) {
            targetSearch = search;
          } else {
            console.warn(`Invalid search: "${search}"! Search must start with a question mark`);
          }
        }
      } catch (err) {
        console.warn('Invalid state returned from identity provider: ', encodedState);
      }
    }

    initJwt(urlParams.get('code')).then(async () => {
      try {
        const accessible_accounts = await baseApiAxios.getAccessibleAccounts(RO_PRINCIPLES_RULES);
        localStorage.setItem('accessible_accounts', accessible_accounts?.accessible_accounts_ids);
      } catch (err) {
        showErrorFromApiOperation('Error while requesting accessible accounts:')(err);
      }
      let targetUrl = './' + targetSearch + targetHash;
      window.open(targetUrl, '_self');
    });
  } else {
    // And finally, after login was successful, the user can start the navigation, by default to the dashboard
    if (window.location.hash) sessionStorage.lastHash = window.location.hash;
    if (!sessionStorage.lastHash) window.location.href = '/#dashboard';

    // after logged in
    // retrieve open search config from dynamodb
    baseApiAxios
      .getSettingsByKey(['open_search_specifications'])
      .then(settings => {
        sessionStorage.setItem('order_search_enable', settings.open_search_specifications.order_search_enable);
      })
      .catch(err => {
        console.debug('failed to request open search config, turn off open search feature');
        sessionStorage.setItem('order_search_enable', false);
      });
  }

  return (
    <>
      {/* Navigation */}
      <nav class="navbar navbar-dark navbar-inverse fixed-top" role="navigation">
        {/* Top Navigation: Text and Icon */}
        <a class="navbar-brand" href="#dashboard">
          <img src={bmwLogoImageSrc} width="25" class="d-inline-block" />
          <span class="d-none d-md-inline-block display-brand-name">{process.env.PORTAL_CONFIG.headerText}</span>
          <span class="d-md-none display-brand-name-short">BMW | AWS</span>
        </a>

        {/* Top Navigation: Right Menu */}
        <div class="btn-toolbar navbar-top-links">
          <div class="dropdown">
            <button
              type="button"
              id="userDropdownMenuButton"
              class="btn dropdown-toggle no-dropdown-arrow"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="username-icon fas fa-user fa-fw" />
              <span id="username" class="d-none d-md-inline display-username" />
              <span class="small d-none d-md-inline display-auth-provider" />
            </button>
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdownMenuButton">
              <h6 class="dropdown-header display-username d-md-none" />
              <a id="userDropdownItemChangePassword" class="dropdown-item d-none" href="#changepassword">
                <i class="fas fa-pencil-alt" /> Change your Portal Password
              </a>
              <a id="userDropdownItemManageAPIKeys" class="dropdown-item" href={linkWithoutSearch('#manageapikeys')}>
                <i class="fas fa-cog" /> Manage your Portal API Keys
              </a>
              <a id="userDropdownItemLogOut" role="button" class="dropdown-item" onclick={() => logoutFromIdp()}>
                <i class="fas fa-power-off" /> Logout
              </a>
            </div>
          </div>

          <button
            type="button"
            class="btn navbar-toggler d-md-none"
            style="color: lightgrey"
            data-bs-toggle="collapse"
            data-bs-target="#sidebar">
            <i class="fas fa-bars" />
          </button>
        </div>
      </nav>

      <div id="page-wrapper">
        {/* Sidebar */}
        <Sidebar />

        {/* Page Content */}
        <div id="page-content-wrapper">
          <div id="banners-container" />

          <div class="container-fluid">
            <div id="page-header-row" class="row">
              <div class="col-lg-12">
                <h1 class="page-header" id="mainTitle" />
              </div>
            </div>

            <div id="mainPage" />
          </div>
        </div>
      </div>

      {/* Toasts */}
      <div class="toast-container position-absolute portal" id="toastPlacement" />

      {/* Release Notes */}
      <div class="container" id="changelog" />

      {/* Footer */}
      <nav class="navbar navbar-inverse fixed-bottom">
        <a class="navbar-brand" href="#releasenotes" data-bs-toggle="modal">
          <span id="portal-version" />
          <small>
            &nbsp;(
            <span id="version-bottom" />)
          </small>
        </a>
        <FooterLink
          text="Create ITSM Ticket"
          hash="#createITSMTicket"
          itemId="FooterItemSupportITSMTicketLink"
          linkId="FooterItemSupportITSMTicket"
          permissions={['create_itsm_tickets']}
        />
        <FooterLink
          text="Ask a Question"
          externalLink="https://atc.bmwgroup.net/confluence/display/DEVOPSPF/questions/all"
          linkId="FooterItemSupportQuestionLink"
          itemId="FooterItemSupportQuestion"
          visible
        />
      </nav>

      <div class="loading-modal">
        <div class="loading-modal-container">
          <div class="spinner-border" style="width:4rem;height:4rem;" role="status" />
        </div>
      </div>

      {/* Modal Popup */}
      <div id="sharedModal" class="modal fade" role="dialog" tabindex="-1" aria-hidden="true">
        <div id="sharedModalDialog" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="sharedModalTitle">
                Modal title
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div class="modal-body" id="sharedModalContent" />

            <div class="modal-footer" id="sharedModalFooter">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Temporary Role Modal Popup */}
      <div id="temporaryRoleModal" class="modal fade" role="dialog" tabindex="-1" aria-hidden="true">
        <div id="temporaryRoleModalDialog" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="temporaryRoleModalTitle">
                <strong>Temporary Account Role Request</strong>
              </h5>
              <button type="button" class="btn-close" id="close-modal-button" aria-label="Close" />
            </div>

            <div class="modal-body" id="temporaryRoleModalContent" />

            <div class="modal-footer" id="temporaryRoleModalFooter">
              <button type="button" class="btn btn-outline-secondary" id="save-modal-button">
                Close and save
              </button>
              <button type="button" class="btn btn-success" id="copy-modal-button">
                Copy link to clipboard
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Distribution List Wizard Modal */}
      <div
        id="dlWizard"
        className="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="dlWizardTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="dlWizardTitle" />
              <button type="button" className="btn-close close-wizard" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body" id="dlWizardBody" />
            <div className="modal-footer" id="dlWizardFooter">
              <button type="button" className="btn btn-outline-secondary close-wizard" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
